<template>
  <b-container class="page-container">
    <Breadcrumbs :bread-crumbs="breadCrumbs" currentpage="Reserveren" />
    <b-form @submit.prevent="createNewSub">
      <h1>Nieuwe gebruiker toevoegen</h1>

      <b-row>
        <b-col sm="12" lg="8">
          <b-card>
            <h4>GEGEVENS VAN DE NIEUWE PERSOON</h4>

            <b-row>
              <b-col sm="12" md="6" class="pb-3">
                <label>Voornaam<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.firstname"
                  maxlength="50"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="12" md="6" class="pb-3">
                <label>Achternaam<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.lastname"
                  maxlength="50"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="8" md="9" class="pb-3">
                <label>Straatnaam<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.street"
                  maxlength="50"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="4" md="3">
                <label>Huisnr.<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.houseNumber"
                  maxlength="50"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="4" class="pb-3">
                <label>Postcode<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.postcode"
                  :max="9999"
                  maxlength="4"
                  required
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); javascript: if (isNaN(this.value)) this.value = this.value.substring(0, this.value.length - 1);"
                ></b-form-input>
              </b-col>
              <b-col cols="8">
                <label>Gemeente<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.city"
                  maxlength="50"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="12" md="6" class="pb-3">
                <label>E-mailadres<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.email"
                  maxlength="50"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="12" md="6">
                <label>Telefoonnummer<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.tel"
                  maxlength="50"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="12">
                <hr />
                <b-form-text id="password-help-block">
                  <p>Een nieuwe wachtwoord moet:</p>
                  <ul>
                    <li>Minimaal 8 karakters lang zijn</li>
                    <li>Minimaal één hoofdletter bevatten (A t/m Z)</li>
                    <li>Minimaal één kleine letter bevatten (a t/m z)</li>
                    <li>Minimaal één cijfer bevatten (0 t/m 9)</li>
                    <li>Minimaal één speciale teken bevatten (!@#$%&)</li>
                  </ul>
                </b-form-text>
              </b-col>
              <b-col sm="12" md="6" class="pb-3">
                <label>Wachtwoord<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.password"
                  required
                  type="password"
                  :state="passwordValidation"
                  min="8"
                  maxlength="50"
                ></b-form-input>
              </b-col>
              <b-col sm="12" md="6">
                <label
                  >Herhaal wachtwoord<span class="text-danger">*</span></label
                >
                <b-form-input
                  v-model="form.confirmPassword"
                  type="password"
                  :state="passwordConfirmState && passwordValidation"
                  required
                  min="8"
                  maxlength="50"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-card>
          <div class="mt-5">
            <b-alert v-if="error" show variant="warning">
              <font-awesome-icon icon="exclamation-circle" />{{ errorMessage }}
            </b-alert>
          </div>
          <b-card class="mt-5">
            <b-row>
              <b-col>
                <h2 class="pt-2">Bevestigen?</h2>
              </b-col>
              <b-col class="text-right">
                <b-button variant="primary" type="submit"
                  >Gebruiker aanmaken
                  <font-awesome-icon
                    v-if="processing"
                    class="fa-spin"
                    far
                    icon="spinner"
                /></b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
import { newSub } from '@/services/AuthorizationService'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      form: {
        email: '',
        password: '',
        confirmPassword: '',
        street: '',
        city: '',
        tel: '',
        houseNumber: '',
        postcode: '',
        firstname: '',
        lastname: ''
      },
      errorMessage: '',
      error: false,
      registering: false,
      formFilled: false,
      processing: false,
      incorrectPassword: false,
      subUserLimitReached: false,
      breadCrumbs: [
        { page: 'index', title: 'Uitleendienst' },
        { page: 'account', title: 'Je account' }
      ]
    }
  },
  metaInfo: function() {
    return {
      title: `Nieuw gebruiker toevoegen`,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst'
    }
  },
  computed: {
    passwordConfirmState() {
      if (this.form.password != '' && this.form.password != null) {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    },
    passwordValidation() {
      let password = this.form.password
      if (password != '') {
        if (password.length < 8) {
          return false
        }

        var numberRegex = /[0-9]/g
        if (!password.match(numberRegex)) {
          return false
        }

        const lowercaseRegex = /[a-z]/g
        if (!password.match(lowercaseRegex)) {
          return false
        }

        const capitalRegex = /[A-Z]/g
        if (!password.match(capitalRegex)) {
          return false
        }

        const symbolRegex = /[!|@|#|$|%|&]/g
        if (!password.match(symbolRegex)) {
          return false
        }

        return true
      }

      return null
    }
  },
  methods: {
    createNewSub: async function() {
      this.processing = true

      if (!this.passwordValidation) {
        this.processing = false
        this.incorrectPassword = true
        return
      }
      if (this.form.password == this.form.confirmPassword) {
        const result = await newSub({
          email: this.form.email,
          password: this.form.password,
          confirmPassword: this.form.confirmPassword,
          street: this.form.street,
          city: this.form.city,
          houseNumber: this.form.houseNumber,
          postcode: this.form.postcode,
          tel: this.form.tel,
          firstname: this.form.firstname,
          lastname: this.form.lastname
        })

        if (result.data.Message === 'MaxUserCountHasBeenReached') {
          this.errorMessage =
            'Het maximale aantal subgebruikers is bereikt, kan sub gebruiker niet aanmaken.'
          this.error = true
          this.processing = false
          return
        }

        if (
          result.data.Message === 'The entered e-mail address is unauthorized.'
        ) {
          this.errorMessage =
            'Er bestaad reeds een sub gebruiker met het opgegeven emailadres, het is niet mogelijk om het zelfde emailadres voor meerdere subgebruikers te gebruiken.'
          this.error = true
          this.processing = false
          return
        }

        this.$router.push({ name: 'account' })
      } else {
        this.processing = false
      }
    }
  }
}
</script>

<style></style>
